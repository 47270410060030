
  import { Options, Vue } from "vue-class-component"
  import { mapGetters, mapState } from "vuex"
  import BankAccountItem from "./BankAccountItem.vue"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/css/index.css"
  import { mapModuleState } from "@/utils/vuex"

  let interval: any

  @Options({
    name: "TheBankAccountList",
    props: {
      loaded: Boolean,
      account: Object,
    },
    components: {
      BankAccountItem,
      Loading,
    },
    mounted() {
      const accountsRefreshInterval = this.$config.accountsRefreshInterval || 90

      if (accountsRefreshInterval != -1) {
        if (interval) clearInterval(interval)

        interval = setInterval(() => {
          this.$store.dispatch("fetchAccounts")
        }, Math.max(10000, accountsRefreshInterval * 1000))
      }
    },
    unmounted() {
      if (interval) {
        clearInterval(interval)
        interval = null
      }
    },
    computed: {
      totalAccountsLoaded(): number {
        return this.$store.state.lokapi.virtualAccountTree.length
      },
      activeVirtualAccountsMiddleware(this: any) {
        const accounts = this.activeVirtualAccounts
        if (!this.account && accounts.length > 0) {
          this.$emit("accountSelected", accounts[0])
        }
        return accounts
      },
      ...mapGetters(["activeVirtualAccounts", "inactiveVirtualAccounts"]),

      ...mapModuleState("lokapi", ["accountsLoading", "accountsLoadingError"]),
    },
    methods: {
      refreshBalanceAndTransactions() {
        this.$lokapi.flushBackendCaches()
        this.$store.dispatch("fetchAccounts")
        this.$emit("refreshTransaction")
      },
    },
  })
  export default class TheBankAccountList extends Vue {}
