
  import { mapGetters } from "vuex"

  import { Options, Vue } from "vue-class-component"
  import Loading from "vue-loading-overlay"
  import DatePicker from "vue-datepicker-next"

  import { ModelListSelect } from "vue-search-select"
  import { Capacitor } from "@capacitor/core"
  import moment from "moment"

  import TransactionListRecent from "./TransactionListRecent.vue"
  import TransactionItem from "./TransactionItem.vue"

  // Assets

  import "vue-datepicker-next/index.css"
  import "vue-search-select/dist/VueSearchSelect.css"
  import "@/assets/datepicker.scss"

  import { mapModuleState } from "@/utils/vuex"
  import UseBatchLoading from "@/services/UseBatchLoading"

  @Options({
    name: "TransactionListModal",
    components: {
      Loading,
      DatePicker,
      TransactionItem,
      ModelListSelect,
    },

    data(this: any) {
      return {
        selectExportLoader: null,
        exportDate: ["", ""],
        datePickerShow: false,
        selectorLabels: {
          day: this.$gettext("day"),
          week: this.$gettext("week"),
          month: this.$gettext("month"),
          year: this.$gettext("year"),
        },
        selectorsOrder: ["day", "week", "month", "year"],
        selectedTimeSpanType: "",
        selectedTimeSpanOffset: 0,
        isTransactionsLoading: false,
        selectedRecipientIdx: null,
        recipientBatchLoader: null,
      }
    },

    created() {
      const [opts] = this.$modal.args.value
      let { account } = opts
      if (account._obj?.getTransactions) {
        account = account._obj
      } else {
        account = account._obj.parent
      }
      const backend = account.parent

      this.recipientBatchLoader = UseBatchLoading({
        genFactory: backend.searchRecipients.bind(backend),
        needMorePredicate: () =>
          this.$recipients.scrollHeight -
            (this.$recipients.scrollTop + this.$recipients.offsetHeight) <=
          50,
        onError: (e) => {
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occured while downloading recipient list"
            )
          )
          throw e
        },
      })
      this.transactionBatchLoader = UseBatchLoading({
        genFactory: this.getTransactions.bind(this),
        needMorePredicate: () => {
          const div = this.$refs.transactionsContainer
          return div.scrollHeight - (div.scrollTop + div.offsetHeight) <= 500
        },
        onError: () => {
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occured while downloading transaction list"
            )
          )
        },
      })
    },
    async mounted() {
      const $recipients = this.$el.querySelector(".menu")

      $recipients.addEventListener(
        "scroll",
        this.recipientBatchLoader.getNextElements.bind(
          this.recipientBatchLoader
        )
      )
      this.$recipients = $recipients
      this.recipientBatchLoader.newGen("")
      this.transactionBatchLoader.newGen("")
    },
    computed: {
      getPlatform(): string {
        return Capacitor.getPlatform()
      },
      isSelectionCurrent(): boolean {
        return moment().isBetween(this.exportDate[0], this.exportDate[1])
      },
      selectedTimeSpan() {
        const now = moment().toDate()
        const timeSpanType = this.selectedTimeSpanType
        const offset = this.selectedTimeSpanOffset
        const dateSelected = moment(now)
          .subtract(-offset, timeSpanType)
          .toDate()
        const [begin, end] = [
          moment(dateSelected).startOf(timeSpanType),
          moment(dateSelected).endOf(timeSpanType),
        ].map((m) => m.toDate())

        return [begin, now < end ? now : end]
      },
      ...mapModuleState("lokapi", ["userProfile"]),
      ...mapGetters(["numericFormat", "dateFormat"]),
    },
    methods: {
      async createCsvFile() {
        const transactions = []
        const [dateBegin, dateEnd] = this.exportDate

        this.isTransactionsLoading = true

        try {
          for await (const t of this.getTransactions()) {
            transactions.push(t)
          }
        } catch (e) {
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occured while downloading transaction list"
            )
          )
          throw e
        } finally {
          this.isTransactionsLoading = false
        }

        let exportFileName
        if (dateBegin && dateEnd) {
          let dateBeginStr = moment(dateBegin).format("YYYY-MM-DD")
          let dateEndStr = moment(dateEnd).format("YYYY-MM-DD")
          exportFileName = `transactions_${dateBeginStr}_${dateEndStr}.csv`
        } else {
          exportFileName = "transactions.csv"
        }

        const columnOrder = [
          "sender",
          "receiver",
          "amount",
          "date",
          "description",
        ]
        let csvDataLine: { [key: string]: string }[] = [
          {
            sender: this.$gettext("Source"),
            receiver: this.$gettext("Target"),
            amount: this.$gettext("Amount"),
            date: this.$gettext("Date"),
            description: this.$gettext("Description"),
          },
        ]

        for (let e of transactions) {
          let name = e.related
          let [sender, receiver] = e.amount.startsWith("-")
            ? [this.userProfile.name, name]
            : [name, this.userProfile.name]
          let data: { [key: string]: string } = {
            sender,
            receiver,
            amount: this.numericFormat(e.amount),
            date: moment(e.date).format("YYYY-MM-DD HH:mm:ss"),
            description: e.description || "",
          }

          for (const s of columnOrder) {
            data[s] = '"' + data[s].replaceAll('"', '""') + '"'
          }

          csvDataLine.push(data)
        }

        return {
          csvContent:
            csvDataLine
              .map((dataLine) =>
                columnOrder.map((header) => dataLine[header]).join(",")
              )
              .join("\r\n") + "\r\n",
          exportFileName,
        }
      },
      async downloadCsvFile() {
        this.selectExportLoader = 1
        const { csvContent, exportFileName } = await this.createCsvFile()
        try {
          await this.$export.download(csvContent, exportFileName, "text/csv")
        } catch (e) {
          this.$msg.error(
            this.$gettext("Transaction list could not be downloaded")
          )
          throw e
        }
        this.$modal.close()
        this.$msg.success(this.$gettext("Transaction list downloaded"))
      },
      async shareCsvFile() {
        this.selectExportLoader = 2
        const { csvContent, exportFileName } = await this.createCsvFile()
        let dateBeginStr, dateEndStr
        if (this.exportDate[0] && this.exportDate[1]) {
          dateBeginStr = moment(this.exportDate[0]).format("YYYY-MM-DD")
          dateEndStr = moment(this.exportDate[1]).format("YYYY-MM-DD")
        } else {
          dateBeginStr = ""
          dateEndStr = ""
        }
        try {
          await this.$export.share(csvContent, exportFileName, [
            dateBeginStr,
            dateEndStr,
          ])
        } catch (e) {
          this.$msg.error(
            this.$gettext("Transaction list could not be downloaded")
          )
          throw e
        }
        this.$modal.close()
        this.$msg.success(this.$gettext("Transaction list shared"))
      },
      disabledDates(date: Date) {
        return date > moment().endOf("day").toDate()
      },
      async *getTransactions() {
        const [opts] = this.$modal.args.value
        const { account } = opts

        let gen
        if (account._obj?.getTransactions) {
          gen = account._obj.getTransactions()
        } else {
          gen = account._obj.parent.getTransactions()
        }

        const [dateBegin, dateEnd] = this.exportDate
        const selectedRecipientName =
          this.recipientBatchLoader.elements[this.selectedRecipientIdx]?.name

        for await (const t of gen) {
          if (dateBegin && t.date < dateBegin) break
          if (selectedRecipientName && selectedRecipientName !== t.related)
            continue
          if (dateEnd && t.date > dateEnd) continue
          yield t
        }
      },

      async onRecipientSearch(recipientsSearchString: any) {
        if (
          this.selectedRecipientIdx !== null &&
          recipientsSearchString === ""
        ) {
          return
        }
        if (
          recipientsSearchString.length > 2 ||
          recipientsSearchString.length === 0
        ) {
          this.recipientBatchLoader.newGen(recipientsSearchString)
        }
      },
    },
    watch: {
      selectedRecipientIdx: async function (newIdx, oldIdx): Promise<void> {
        this.onRecipientSearch("")
        this.transactionBatchLoader.newGen()
      },
      exportDate: async function (newExportDate): Promise<void> {
        let [newBegin, newEnd] = newExportDate
        const [normBegin, normEnd] = [
          newBegin ? moment(newBegin).startOf("day").toDate() : null,
          newEnd ? moment(newEnd).endOf("day").toDate() : null,
        ]
        if (
          normBegin &&
          normEnd &&
          (+newBegin != +normBegin || +newEnd != +normEnd)
        ) {
          this.exportDate = [normBegin, normEnd]
          return
        }
        this.transactionBatchLoader.newGen()
      },
    },
  })
  export default class TheTransactionList extends Vue {}
