
  import { Options, Vue } from "vue-class-component"

  import TransactionListRecent from "./TransactionListRecent.vue"

  import { mapModuleState } from "@/utils/vuex"

  @Options({
    name: "TheTransactionList",
    components: {
      TransactionListRecent,
    },
    props: {
      refreshToggle: Boolean,
      account: Object,
    },
    computed: {
      ...mapModuleState("lokapi", ["transactionsLoading", "lastTransactions"]),
    },
    mounted() {
      console.log("TransactionList", this.account)
    },
  })
  export default class TheTransactionList extends Vue {}
