import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60b703da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-footer" }
const _hoisted_2 = { class: "columns is-mobile menu" }
const _hoisted_3 = { class: "column has-text-centered mb-2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "icon-text" }
const _hoisted_6 = { class: "icon" }
const _hoisted_7 = { class: "column has-text-centered mb-2" }
const _hoisted_8 = {
  disabled: true,
  class: "button custom-button is-request has-text-weight-medium is-rounded action"
}
const _hoisted_9 = { class: "icon-text" }
const _hoisted_10 = { class: "icon" }
const _hoisted_11 = { class: "column has-text-centered mb-2" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "icon-text" }
const _hoisted_14 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            disabled: !_ctx.account,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('MoneyTransferModal', { account: _ctx.account }))),
            class: "button custom-button is-pay has-text-weight-medium is-rounded action"
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_fa_icon, {
                  icon: "arrow-circle-up",
                  class: "fa-lg"
                })
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$gettext("Pay")), 1)
            ])
          ], 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_fa_icon, {
                  icon: "arrow-circle-down",
                  class: "fa-lg"
                })
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$gettext("Request")), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            disabled: !_ctx.account,
            class: "button custom-button is-top-up has-text-weight-medium is-rounded action",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal('MoneyCreditModal', { account: _ctx.account })))
          }, [
            _createElementVNode("span", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, [
                _createVNode(_component_fa_icon, {
                  icon: "plus-circle",
                  class: "fa-lg"
                })
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$gettext("Top up")), 1)
            ])
          ], 8, _hoisted_12)
        ])
      ])
    ])
  ]))
}