import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b9a24a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "login" }
const _hoisted_2 = { class: "login-container" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "field mb-5 has-addons" }
const _hoisted_6 = { class: "control has-icons-left is-expanded" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "icon is-small is-left" }
const _hoisted_9 = {
  key: 0,
  class: "control"
}
const _hoisted_10 = { class: "button is-info" }
const _hoisted_11 = { class: "field mb-2" }
const _hoisted_12 = { class: "control has-icons-left" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "icon is-small is-left" }
const _hoisted_15 = {
  key: 0,
  class: "forgot-password mb-4"
}
const _hoisted_16 = { class: "login-buttons" }
const _hoisted_17 = { class: "control has-text-centered" }
const _hoisted_18 = {
  type: "submit",
  class: "button is-login"
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "control has-text-centered" }
const _hoisted_21 = {
  key: 1,
  class: "has-text-danger has-text-centered"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$config.loginLogoUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.$config.loginLogoUrl,
              class: "pt-2 pb-5"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                name: "login",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                class: "input",
                placeholder: _ctx.$gettext('Email')
              }, null, 8, _hoisted_7), [
                [
                  _vModelText,
                  _ctx.email,
                  void 0,
                  { trim: true }
                ]
              ]),
              _createElementVNode("span", _hoisted_8, [
                _createVNode(_component_fa_icon, { icon: "envelope" })
              ])
            ]),
            (_ctx.biometryEnabled && _ctx.biometryAvailable)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                  _createElementVNode("a", _hoisted_10, [
                    _createVNode(_component_fa_icon, {
                      icon: "fingerprint",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.requestBiometricAuthentication()))
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("p", _hoisted_12, [
              _withDirectives(_createElementVNode("input", {
                name: "password",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                class: "input",
                type: "password",
                placeholder: _ctx.$gettext('Password')
              }, null, 8, _hoisted_13), [
                [_vModelText, _ctx.password]
              ]),
              _createElementVNode("span", _hoisted_14, [
                _createVNode(_component_fa_icon, { icon: "lock" })
              ])
            ])
          ]),
          (!_ctx.$config?.hideResetPassword && _ctx.canResetPassword)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'Reset password' }))),
                  type: "button"
                }, _toDisplayString(_ctx.$gettext("Forgot password ?")), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_17, [
                _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$gettext("Sign in")), 1)
              ])
            ]),
            (
                !_ctx.$config?.hideAccountCreate && (_ctx.$config.signUpUrl || _ctx.canSignup)
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, [
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClickSignUp())),
                      type: "button",
                      class: "button create-account"
                    }, _toDisplayString(_ctx.$gettext("Sign up")), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.fail)
            ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.fail), 1))
            : _createCommentVNode("", true)
        ], 32)
      ])
    ]),
    _createVNode(_component_router_view)
  ]))
}