
  import { Options, Vue } from "vue-class-component"
  import { RestExc } from "@lokavaluto/lokapi-browser"
  import { e as RequestExc } from "@0k/types-request"

  @Options({
    name: "Login",
    data() {
      return {
        email: "",
        password: "",
        fail: "",
        success: "",
        biometryEnabled: false, // User preference
        biometryAvailable: false, // Biometry available and credential saved
        canResetPassword: false,
        canSignup: false,
      }
    },
    async mounted() {
      const savedLoginEmail = this.$persistentStore.get("loginEmail")
      if (savedLoginEmail) this.email = savedLoginEmail

      this.getCanResetPassword()
      if (!this.$config.hideAccountCreate && !this.$config.signUpUrl)
        this.getCanSignup()
      ;(await this.getHasBiometricCredentialsEnabled()) &&
        (await this.getHasBiometricCredentialsAvailable()) &&
        (await this.requestBiometricAuthentication())
    },
    methods: {
      onClickSignUp() {
        this.$config.signUpUrl
          ? window.open(this.$config.signUpUrl)
          : this.$router.push({ name: "Signup" })
      },
      async getCanResetPassword() {
        this.canResetPassword = await this.$lokapi.canResetPassword()
      },
      async getCanSignup() {
        this.canSignup = await this.$lokapi.canSignup()
      },
      async getHasBiometricCredentialsEnabled() {
        this.biometryEnabled =
          (await this.$localSettings.load())?.biometryEnabled || false
        return this.biometryEnabled
      },
      async getHasBiometricCredentialsAvailable() {
        this.biometryAvailable = await this.$biometry.hasCredentialsAvailable(
          "login"
        )
        return this.biometryAvailable
      },
      async requestBiometricAuthentication(): Promise<void> {
        let credentials: any
        try {
          credentials = await this.$biometry.challenge("login")
        } catch (e) {
          console.log(e)
          credentials = false
        }
        if (credentials) {
          this.password = credentials.password
          this.email = credentials.username
          this.submit()
        }
      },
      async submit(): Promise<void> {
        try {
          this.$loading.show()
          await this.$store.dispatch("login", {
            login: this.email,
            password: this.password,
          })
          this.success = this.$gettext("Connection successful")
          this.$router.push({ name: "dashboard" })
          this.$persistentStore.set("loginEmail", this.email)
        } catch (e) {
          // { APIRequestFailed, InvalidCredentials }
          if (e instanceof RestExc.APIRequestFailed) {
            this.fail = this.$gettext(
              "Request refused by remote server. Contact your administrator"
            )
            return
          }
          if (e instanceof RestExc.InvalidCredentials) {
            this.fail = this.$gettext("Invalid credentials")
            return
          }
          if (e instanceof RequestExc.RequestFailed) {
            this.fail = this.$gettext("Request failed to remote server.")
            return
          }
          this.fail = this.$gettext(
            "Unexpected issue when attempting to connect to remote server."
          )
          throw e
        } finally {
          this.$loading.hide()
        }

        const biometryAvailable = await this.$biometry.isAvailable()
        if (!biometryAvailable) return

        const prefs = (await this.$localSettings.load()) || {}
        const biometryEnabled = prefs?.biometryEnabled
        if (biometryEnabled === false) return
        if (
          biometryEnabled === null ||
          typeof biometryEnabled === "undefined"
        ) {
          const answer = await this.$dialog.show({
            title: this.$gettext("Enable biometric login"),
            content: this.$gettext(
              "Would you like to use your device's biometric (fingerprint, face recognition, ...) capability to login ?"
            ),
            buttons: [
              { label: this.$gettext("Yes"), id: "yes" },
              { label: this.$gettext("No"), id: "no" },
              { label: this.$gettext("Ask me later"), id: "later" },
            ],
          })
          console.log(answer)
          if (answer === "later") return
          if (answer === "no") {
            prefs.biometryEnabled = false
            await this.$localSettings.save(prefs)
            return
          }
          prefs.biometryEnabled = true
          await this.$localSettings.save(prefs)
        }
        // biometryEnabled is true
        const hasCredentialsAvailable =
          await this.$biometry.hasCredentialsAvailable("login")
        console.log("has", hasCredentialsAvailable)
        if (hasCredentialsAvailable) return
        try {
          await this.$biometry.saveCredentials("login", {
            username: this.email,
            password: this.password,
          })
        } catch (e) {
          this.$msg.error(
            this.$gettext(
              "Unexpected issue occurred while saving your credentials"
            )
          )
          throw e
        }

        this.$msg.success(this.$gettext("Biometric login successfully set up"))
      },
    },
  })
  export default class Login extends Vue {}
