
  import { Options, Vue } from "vue-class-component"

  import TheBankAccountList from "@/components//TheBankAccountList.vue"
  import TheTransactionList from "@/components/TheTransactionList.vue"
  import TheDashboardFooter from "@/components/TheDashboardFooter.vue"

  @Options({
    name: "core",
    props: {
      msg: String,
    },
    data() {
      return {
        refreshToggle: false,
        account: null,
      }
    },
    components: {
      TheTransactionList,
      TheBankAccountList,
      TheDashboardFooter,
    },
    methods: {
      refreshTransaction() {
        // This change is propagated through props to children components
        this.refreshToggle = !this.refreshToggle
      },
      accountSelected(account: any) {
        if (account?._obj?.internalId === this.account?._obj?.internalId) return
        this.account = account
      },
    },
  })
  export default class Core extends Vue {}
