
  import { Options, Vue } from "vue-class-component"
  import { mapGetters } from "vuex"

  import BankAccountItem from "./BankAccountItem.vue"

  @Options({
    name: "MoneyCreditModal",
    components: {
      BankAccountItem,
    },
    data() {
      return {
        creditOrderUrl: "",
        selectedCreditAccount: null,
        showCreditRefreshNotification: false,
        amount: "",
      }
    },
    mounted() {
      this.setFocus()
      this.resetCredit()
    },
    created() {
      const [opts] = this.$modal.args.value
      const { account } = opts
      let subAccounts
      if (account._obj?.getAccounts) {
        subAccounts = account.subAccounts
      } else {
        subAccounts = [account]
      }
      this.creditableMoneyAccounts = subAccounts.filter(
        (a: any) => a.creditable
      )
    },
    computed: {
      amountError() {
        if (!this.selectedCreditAccount) return ""
        if (this.amount === "") return ""
        const minCreditAmount = this.selectedCreditAccount.minCreditAmount || 1
        if (this.amount < minCreditAmount) {
          return this.$gettext(
            "The minimum top up amount must be equal or greater than %{ amount }",
            {
              amount: minCreditAmount + " " + this.selectedCreditAccount?.curr,
            }
          )
        }
        const maxCreditAmount = this.selectedCreditAccount.maxCreditAmount || 0
        if (maxCreditAmount && this.amount > maxCreditAmount) {
          return this.$gettext(
            "The maximum top up amount must be equal or less than %{ amount }",
            {
              amount: maxCreditAmount + " " + this.selectedCreditAccount?.curr,
            }
          )
        }
        return false
      },
    },
    methods: {
      resetCredit(): void {
        this.creditOrderUrl = ""
        this.amount = ""
        this.errors = {
          minCreditAmount: false,
        }
        this.selectedCreditAccount =
          this.creditableMoneyAccounts.length === 1
            ? this.creditableMoneyAccounts[0]
            : false
      },
      setSelectedCreditAccount(account: any): void {
        this.amount = ""
        this.errors = {
          minCreditAmount: false,
          maxCreditAmount: false,
        }
        this.selectedCreditAccount = account
      },
      async newLinkTab() {
        // This to ensure we are left with 2 decimals only
        this.amount = this.amount.toFixed(2)
        try {
          if (!this.selectedCreditAccount) {
            if (this.creditableMoneyAccounts.length > 1) {
              throw new Error("Unexpected multiple creditable account found.")
            }
            this.selectedCreditAccount = this.creditableMoneyAccounts[0]
          }
          this.$loading.show()
          let url = await this.selectedCreditAccount._obj.getCreditUrl(
            this.amount
          )
          this.creditOrderUrl = url.order_url
        } catch (error) {
          console.log("Payment failed:", error)
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occurred while attempting to top up your account"
            )
          )
        } finally {
          this.$loading.hide()
        }
      },
      navigateToCreditOrder(): void {
        window.open(this.creditOrderUrl, "_blank")
        this.creditOrderUrl = ""
        this.amount = 0
        this.showCreditRefreshNotification = true
      },
      closeAndRefresh(): void {
        this.close(true)
        this.$lokapi.flushBackendCaches()
        this.$store.dispatch("fetchAccounts")
      },
      close(refreshTransactions: boolean): void {
        this.showCreditRefreshNotification = false
        this.$modal.close(refreshTransactions)
      },
      setFocus() {
        this.$nextTick(() => {
          if (this.$refs.amountcredit) {
            this.$refs.amountcredit.value = null
            this.$refs.amountcredit.focus()
            this.$refs.amountcredit.select()
          }
        })
      },
    },
  })
  export default class MoneyCreditModal extends Vue {}
