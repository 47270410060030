
  import { Options, Vue } from "vue-class-component"

  @Options({
    name: "TheDashboardFooter",
    props: {
      account: Object,
    },
    methods: {
      async openModal(label: string, ...args: any[]) {
        const refreshTransaction = await this.$modal.open(label, ...args)
        if (refreshTransaction) this.$emit("refreshTransaction")
      },
    },
  })
  export default class TheDashboardFooter extends Vue {}
