import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36a35ca8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-size-5 is-flex-grow-1" }
const _hoisted_2 = {
  key: 0,
  class: "account-backend is-size-6"
}
const _hoisted_3 = { class: "is-align-items-center is-flex" }
const _hoisted_4 = {
  key: 0,
  class: "is-size-6-mobile is-size-4-tablet account-bal"
}
const _hoisted_5 = {
  key: 1,
  class: "is-size-6-mobile is-size-4-tablet account-bal inactive"
}
const _hoisted_6 = { class: "is-size-6-mobile is-size-5-tablet account-curr" }
const _hoisted_7 = {
  key: 0,
  class: "sub-accounts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BankAccountItem = _resolveComponent("BankAccountItem", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["account", { active: _ctx.active }])
  }, [
    _createElementVNode("div", {
      class: "custom-inner-card card px-5 py-2 is-flex",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('accountSelected', _ctx.account)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "name", {}, () => [
          _createTextVNode("default name")
        ], true),
        (_ctx.isMultiCurrency && !_ctx.isSub)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.backend), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.numericFormat(parseFloat(_ctx.bal))), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, "-.---,--")),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.curr), 1)
      ])
    ]),
    (_ctx.subAccounts && _ctx.subAccounts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subAccounts, (account) => {
            return (_openBlock(), _createBlock(_component_BankAccountItem, {
              bal: account.bal,
              curr: account.curr,
              backend: account.backend,
              type: account.type,
              active: account.active,
              isSub: true,
              class: "mt-4 subaccount",
              onAccountSelected: ($event: any) => (_ctx.$emit('accountSelected', account))
            }, {
              name: _withCtx(() => [
                _createTextVNode(_toDisplayString(account.name()), 1)
              ]),
              _: 2
            }, 1032, ["bal", "curr", "backend", "type", "active", "onAccountSelected"]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}