import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card custom-card custom-card-padding" }
const _hoisted_2 = { class: "is-flex-direction-column is-align-items-center is-justify-content-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionListRecent = _resolveComponent("TransactionListRecent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TransactionListRecent, {
        refreshToggle: _ctx.refreshToggle,
        account: _ctx.account
      }, null, 8, ["refreshToggle", "account"])
    ])
  ]))
}