
  import { Options, Vue } from "vue-class-component"
  import { e as LokapiExc } from "@lokavaluto/lokapi-browser"

  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/css/index.css"
  import RecipientItem from "@/components/RecipientItem.vue"
  import BankAccountItem from "@/components/BankAccountItem.vue"

  import UseBatchLoading from "@/services/UseBatchLoading"

  @Options({
    name: "MoneyTransferModal",
    components: {
      Loading,
      RecipientItem,
      BankAccountItem,
    },
    data() {
      return {
        recipientsSearchString: "",
        transferOngoing: false,
        recipientsSearchError: false,
        selectedRecipient: null,
        ownSelectedAccount: null,
        amount: null,
        message: null,
        errors: {
          balance: false,
          amount: false,
        },
      }
    },
    created() {
      const [opts] = this.$modal.args.value
      let { account } = opts
      if (account._obj?.getTransactions) {
        account = account._obj
      } else {
        account = account._obj.parent
      }
      const backend = account.parent

      this.recipientBatchLoader = UseBatchLoading({
        genFactory: backend.searchRecipients.bind(backend),
        needMorePredicate: () =>
          this.$refs.recipientsContainer.scrollHeight -
            (this.$refs.recipientsContainer.scrollTop +
              this.$refs.recipientsContainer.offsetHeight) <=
          50,
        onError: (e) => {
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occured while downloading recipient list"
            )
          )
          console.error(e)
        },
      })
    },
    mounted() {
      this.setFocus("searchRecipient")
      this.recipientBatchLoader.newGen("")
    },
    computed: {
      ownCurrenciesRecipients(): Array<any> {
        let currencyIds = this.$store.getters.activeVirtualAccounts.map(
          (a: any) => a.currencyId
        )
        return this.recipientBatchLoader.elements.value.filter((p: any) => {
          return currencyIds.indexOf(p.backendId) > -1
        })
      },
    },
    methods: {
      async handleClickRecipient(recipient: any): Promise<void> {
        this.selectedRecipient = recipient
        this.selectedRecipient.currencySymbol = await recipient.getSymbol()
        this.ownSelectedAccount =
          this.$store.getters.activeVirtualAccounts.find(
            (va: any) => va.currencyId === recipient.backendId
          )
        this.$modal.next()
        this.errors.balance = false
        this.errors.amount = false
        this.setFocus("amountSend")
      },
      async sendTransaction(): Promise<void> {
        if (this.transferOngoing) {
          console.log(
            "Debounced `sendTransaction()` call as another transfer is ongoing..."
          )
          return
        }
        this.transferOngoing = true

        this.errors.amount = false
        this.errors.balance = false
        if (this.amount <= 0) {
          this.errors.amount = this.$gettext(
            "Amount to send must be greater than 0"
          )
          this.transferOngoing = false
          return
        }
        // This to ensure we are left with 2 decimals only
        this.amount = parseFloat(this.amount).toFixed(2)
        try {
          this.$store.commit("setRequestLoadingAfterCreds", true)
          await this.selectedRecipient.transfer(
            this.amount.toString(),
            this.message
          )
        } catch (err: any) {
          if (err instanceof LokapiExc.InsufficientBalance) {
            this.errors.balance = this.$gettext(
              "Transaction was refused due to insufficient balance"
            )
            return
          }
          if (err instanceof LokapiExc.InactiveAccount) {
            this.$msg.error(
              this.$gettext("Target account is inactive.") +
                "<br/>" +
                this.$gettext("You can't send money to this account.")
            )
            return
          }
          if (err.message === "User canceled the dialog box") {
            // A warning message should have already been sent
            return
          }
          this.$msg.error(
            this.$gettext(
              "An unexpected issue occurred during the money transfer. " +
                "We are sorry for the inconvenience."
            ) +
              "<br/>" +
              this.$gettext(
                "You can try again. If the issue persists, " +
                  "please contact your administrator."
              )
          )

          console.error("Payment failed:", err)
          return
        } finally {
          this.transferOngoing = false
          this.$loading.hide()
          this.$store.commit("setRequestLoadingAfterCreds", false)
        }
        this.errors.balance = false
        this.errors.amount = false
        this.close(true)
        this.$msg.success(
          this.$gettext("Payment issued to %{ name }", {
            name: this.selectedRecipient.name,
          })
        )
        if (!this.selectedRecipient.is_favorite) {
          this.$dialog
            .show({
              title: this.$gettext("Add as favorite"),
              content: this.$gettext(
                "Do you want to add %{ name } to your favorite list ?",
                { name: this.selectedRecipient.name }
              ),
              buttons: [
                { label: this.$gettext("Add"), id: "add" },
                { label: this.$gettext("Later"), id: "later" },
              ],
            })
            .then(async (result: any) => {
              if (result === "later") return
              try {
                await this.selectedRecipient.toggleFavorite()
              } catch (err: any) {
                // XXXvlab: using ``.then`` makes it trigger outside of
                // view js grasp.
                this.$errorHandler(err)
                return
              }
              this.$msg.success(
                this.$gettext("%{ name } was added to your favorite list", {
                  name: this.selectedRecipient.name,
                })
              )
            })
        }
        await this.$store.dispatch("fetchAccounts")
      },
      close(refreshTransactions: any = false) {
        this.searchName = ""
        this.amount = 0
        this.activeClass = 0
        this.$modal.close(refreshTransactions)
      },
      setFocus(refLabel: string) {
        this.$nextTick(() => {
          const ref = this.$refs[refLabel]
          ref.focus()
          ref.select()
        })
      },
    },
  })
  export default class MoneyTransferModal extends Vue {}
