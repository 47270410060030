
  import { Options, Vue } from "vue-class-component"
  import TransactionItem from "./TransactionItem.vue"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/css/index.css"
  import { mapModuleState } from "@/utils/vuex"

  let timeout: any

  function clearRefresh() {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }

  function setupRefresh(fn: () => void, ms: number) {
    clearRefresh()
    timeout = setTimeout(fn, ms)
  }

  @Options({
    name: "TransactionListRecent",
    props: {
      refreshToggle: Boolean, // change of this props requests a refresh
      account: Object,
    },
    components: {
      TransactionItem,
      Loading,
    },
    data(this: any) {
      return {
        transactions: [],
        isTransactionsBatchLoading: false,
        isTransactionsLoading: false,
      }
    },
    mounted() {
      this.setRefreshIfNeeded()
      this.resetTransactionsGen()
    },
    unmounted() {
      clearRefresh()
    },
    computed: {},

    methods: {
      setRefreshIfNeeded() {
        const transactionsRefreshInterval =
          this.$config.transactionsRefreshInterval || 90
        if (transactionsRefreshInterval != -1) {
          setupRefresh(
            this.resetTransactionsGen.bind(this.resetTransactionsGen),
            transactionsRefreshInterval * 1000
          )
        }
      },
      async getNextFilteredTransactions() {
        if (!this.transactionGen) return
        this.isTransactionsLoading = true
        let transactions = []
        while (transactions.length < 5) {
          let next
          try {
            next = await this.transactionGen.next()
          } catch (e) {
            this.isTransactionsLoading = false
            this.$msg.error(
              this.$gettext(
                "An unexpected issue occured while downloading transaction list"
              )
            )
            throw e
          }
          if (next.done) {
            this.transactionGen = null
            break
          }
          transactions.push(<any>next.value)
        }
        this.isTransactionsLoading = false
        this.transactions = transactions
      },
      resetTransactionsGen() {
        if (this.account._obj?.getTransactions) {
          this.transactionGen = this.account._obj.getTransactions()
        } else {
          this.transactionGen = this.account._obj.parent.getTransactions()
        }
        this.$nextTick(() => this.getNextFilteredTransactions())
        this.setRefreshIfNeeded()
      },
    },
    watch: {
      refreshToggle: function () {
        this.resetTransactionsGen()
      },
    },
  })
  export default class TransactionListRecent extends Vue {}
